
/***
 * GERAL
 ***/
$(function() {
    $('.animsition').animsition().one('animsition.inStart',function(){
        App.SetToggle();
        App.SetGaleria();
        App.SetBanner();
        App.SetCarouselMobile();
        App.SetHeaderFixed(80);
        App.SetValidate();
        App.SetMaskInput();
        App.SetInputError();
        App.SetFormSuccess();
        App.SetAnimatescroll(150);
        App.SetWow(50);

        $('html').addClass('loaded');
        
        // Cookies
        if (localStorage.hasOwnProperty('verify_cookies')) {
            $('.Cookies').addClass('_hide');
        }

        $('.Cookies .Button').click(function(event) {
            event.preventDefault();
            localStorage.setItem('verify_cookies', true);
            $('.Cookies').addClass('_hide');
        });

        $('.tabs-content').each(function(index, el) {
            $('.Planos .tabs-content .tabs>li').on('mouseover', function(event) {
                $(this).addClass('hover');
            })
            .on( "mouseout", function() {
                $(this).removeClass('hover');
            });
            $('.tabs-content .tabs').on('click', 'a', function(event) {
                event.preventDefault();

                target = $(this).attr('href');

                $('.tabs-content .itens .tab').addClass('_hide');
                $(target).removeClass('_hide');

                if ( $(window).width() <= 768 ) {
                    $(target).animatescroll({
                        scrollSpeed: 800,
                        easing: 'easeOutExpo',
                        padding: 150,
                    });
                }

                $('.Planos .tabs-content .tabs>li').trigger('mouseout');
            });
            
        });

        $('.Perguntas').each(function(index, el) {
            $('.Perguntas .item').on('click', '.link', function(event) {
                event.preventDefault();

                target = $(this).attr('href');

                $(this).toggleClass('_active');
                $(target).toggleClass('_hide');
            });
            
        });


        $(".manual .busca").keyup(function(){
            var texto = $(this).val();

            
            $(".itens-arquivos .item .titulo").parent().css("display", "block");
            $(".itens-arquivos .item .titulo").each(function(){
                if($(this).text().toLowerCase().indexOf(texto) < 0)
                   $(this).parent().css("display", "none");
            });
        });

        // scroll
        $('body').scrollspy({
            target: '._col-sidebar .menu',
            offset: 50
        });

        // Polichat
        (function(p,o,l,i,c,h,a,t){
        p['PolichatObject'] = c;p[c]=p[c]||function(){
        (p[c].q=p[c].q||[]).push(arguments);},h=o.createElement(l);p[c].t=p[c].t||new Date();
        a=o.getElementsByTagName(l)[0];h.async=1;h.src=i;a.parentNode.insertBefore(h,a);
        })(window,document,'script','https://ms.polichat.com.br/tags/public/services/serv.js','poli');
        poli('create',973);
        poli('send','webchat', {uid: '847@polichat.webchat'});
    });
});


